
import {
  defineComponent,
  reactive,
  ref,
  toRef,
  createVNode,
  toRaw,
  toRefs
} from "vue";
import { getUserList } from "@/api/hrUser"

export default defineComponent({
  name: "selectUser",
  mixins: [],
  props: {
    title: {
      type: String,
      default: "选择用户"
    },
    total: {
      type: String,
      default: ""
    }
  },
  components: {},
  emits: ["ok"],
  setup(props, context) {
    const title = toRef(props, "title");
    const total = toRef(props, "total");

    const ok = () => {
     
      context.emit("ok",state.selectData);
    };

    const state = reactive({
      confirmLoading: false,
      loading: false,
      selectUserName: "",
      dataSource: [

      ],
      columns : [
       
        {
          title: '姓名',
          dataIndex: 'realname',
        },
        {
          title: '电话',
          dataIndex: 'phone',
        },
      
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        // pageSizeOptions: ['5', '8', '10'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      selectData:{}
    });
   
     const onSelectChange = (key,record) => {
     state.selectData=record[0] || {}
    };
    const rowSelection = {
      type: 'radio',
      onChange: onSelectChange,

    };
      const getDataSource = () => {
      state.confirmLoading = true
      getUserList({
        pageSize: state.pagination.pageSize,
        pageNo: state.pagination.current,
        username:state.selectUserName? '*'+state.selectUserName+'*' :''
      }).then(res => {
        state.dataSource = res.records
        state.pagination.total=res.total
      }).finally(()=>{
        state.confirmLoading = false
      })
    }
   function   handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      state.pagination = pagination
     getDataSource()
      
    }
    const edit = () => {
      state.loading = true;
      state.pagination.current=1
      getDataSource()
    };
    const onSearch = e => {
      state.selectUserName=e
      state.pagination.current=1
      getDataSource()
    };
    return {
      ...toRefs(state),
      title,
      total,
      ok,
      edit,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      onSearch,
      handleTableChange,
      rowSelection,
      onSelectChange,
      getDataSource
    };
  }
});


import { computed, defineComponent, reactive, ref, onMounted, watch, toRefs, Ref,nextTick ,onBeforeMount,onBeforeUnmount} from 'vue'
import type { UnwrapRef } from 'vue'
import { useStore } from 'vuex'
import { getByPhone, EDIT_USER, getTaskList, getFormKey, getProcessByActi,getRegUserInfo,updateVariables,idCardCheck } from '@/api/hrUser'
import { hrUserFormState } from '@/views/HrUser/data'
import { useRouter } from 'vue-router'
import { getUserPhone, getUserInfo } from '@/utils/auth'
import BasicsTab from '@/views/HrUser/HrUserStep/BasicsTab.vue'
import EducationTab from '@/views/HrUser/HrUserStep/EducationTab.vue'
import PostTab from '@/views/HrUser/HrUserStep/PostTab.vue'
import ResultTab from '@/views/HrUser/HrUserStep/ResultTab.vue'
import JobInformation from '@/views/HrUser/HrUserStep/JobInformation.vue'
import IdentityCardTab from '@/views/HrUser/HrUserStep/IdentityCardTab.vue'
import TreatmentTab from '@/views/HrUser/HrUserStep/TreatmentTab.vue'
import QualificationTab from '@/views/HrUser/HrUserStep/QualificationTab.vue'
import AcademicTitleTab from '@/views/HrUser/HrUserStep/AcademicTitleTab.vue'
import HistoryList from '@/views/HrUser/modules/HistoryList.vue'
import { message,Modal } from 'ant-design-vue'
import merge from 'webpack-merge';

import { isArray, isString } from 'lodash'

export default defineComponent({
  components: {
    IdentityCardTab,
    BasicsTab,
    PostTab,
    EducationTab,
    ResultTab,
    JobInformation,
    TreatmentTab,
    QualificationTab,
    AcademicTitleTab,
    HistoryList
  },
  setup () {
    const router = useRouter()
    const activeKey = ref(0)
    const store = useStore()
    const userInfo = getUserInfo()
    const idCardCheckFlag =ref(false)
    const state: {
      formState: any;
      confirmLoading: boolean;
      mode: string;
      historicFlow: Array<any>;
      query: any;
      instanceId: string;
      taskId:any
    } = reactive({
      formState: {} as any,
      confirmLoading: false,
      mode: 'left',
      historicFlow: [],
      query: {},
      instanceId: '',
      taskId:''
    })
    if (userInfo) {
      state.formState.mobilePhone = userInfo.phone
    } else {
      state.formState.mobilePhone = getUserPhone()
    }
    function toNextTab  (key, result) {

      state.confirmLoading = true
      if (result && typeof result === 'object') {
        state.formState = { ...state.formState, ...result, }
        if(key==6){

            getProc(state.formState.activitiId).then( res => {
          if(res){
           state.instanceId=res.instanceId

          }

      })
        }
        if(key==5&&state.taskId){
           //增加流程变量接口 2022/8/9
          updateVariables({"formJson": JSON.stringify(state.formState),"taskId": state.taskId}).then(res=>{
               console.log(res,'ressss');

          }).catch(err=>{
               console.log(err,'errrrrr');

          })
        }
        /**
         * 修改表单操作
         * 判断stage = 10, 等于10说明已经提交流程，删除stage属性
         **/
        if (Object.keys(state.query).includes('isActiviti')) {
          delete result.stage
        }

        if(result.name){
          let obj ={
            idCard:result.identityCardCode,
            name:result.name,
          }
          try {
          idCardCheck(obj).then( idCardres=>{
          console.log(idCardres,'效验身份证');
          if(!idCardres.desc || idCardres.desc=='不一致'){
            message.warning('身份证号码与姓名不匹配!')
            return
          }else{
             result.entryType= state.formState.entryType
            EDIT_USER(result).then(res => {
              console.log('----------88888888888888888888--------------------------',res);
              activeKey.value = key
            }).finally(() => {
              state.confirmLoading = false
            })
          }
          }).catch(err=>{
          }).finally(() => {
              state.confirmLoading = false
            })
          } catch (error) {
            message.warning('身份证号码与姓名验证失败!')
            state.confirmLoading = false
          }

        }else{
    result.entryType= state.formState.entryType
            EDIT_USER(result).then(res => {
              console.log('----------88888888888888888888--------------------------',res);

              activeKey.value = key
            }).finally(() => {
              state.confirmLoading = false
            })
        }

      } else {
        activeKey.value = key
        state.confirmLoading = false
      }

    }
    const scrollTop = ref(0)

    const checkString=(str) =>{
  if (str.length > 3 && str.length % 2 === 0) { // 判断字符串长度是否大于3且为偶数
    var halfLength = str.length / 2;
    var firstHalf = str.substring(0, halfLength);  // 获取字符串的前半部分
    var secondHalf = str.substring(halfLength);  // 获取字符串的后半部分

    return firstHalf === secondHalf; // 判断前半部分和后半部分是否相同
  }

  return false; // 字符串长度不符合要求，直接返回 false
}
    function goTop () {
      let timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn () {
        if (scrollTop.value > 0) {
          scrollTop.value -= 250
          document.body.scrollTop = document.documentElement.scrollTop = scrollTop.value
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
        }
      })
    }
    function handleScroll () {
      scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    }

    function changeMode (e) {
      if (e.target.value === 'right') {

      }
    }
      const postDisabled = computed(() => {
      if (router.currentRoute.value.query.departId) {
        return true
      }
        return false
      })

    watch(
      () => activeKey.value,
      () => {
        handleScroll()
        goTop()
      }
    )
    watch(
      () =>state.formState.activitiId,
      () => {
        if(state.formState.activitiId){
        getProc(state.formState.activitiId).then( res => {
          if(res){
       state.instanceId=res.instanceId

          }
      })
        }

      },
      {immediate:true}
    )

    onMounted(() => {
    state.query = router.currentRoute.value.query
    if(state.query.departId && state.query.departName ){
    getRegUserInfo({id:state.query.departId}).then(res=>{
          if(res){
           router.replace({
             query : merge(router.currentRoute.value.query,{branchtype:res.companyType}) as any
           })
          state.query = router.currentRoute.value.query
          }

        }).catch(err=>{
          if(err.message=='未找到对应数据'){
              router.replace({
             query : merge(router.currentRoute.value.query,{branchtype:'总院'}) as any
           })
          state.query = router.currentRoute.value.query

          }
          //    else{
          //     Modal.confirm({
          //     title: () => '获取公司类型数据失败,请重新进入!',
          //     cancelText: () => '退出',
          //     icon: () => '',
          //     onOk() {
          //         // router.go(-1)
          //           router.push({
          //           path: '/hr/login',
          //           query: {
          //             ...state.query
          //           }
          //         })
          //     },
          //     onCancel() {
          //           router.push({
          //           path: '/hr/login',
          //           query: {
          //             ...state.query
          //           }
          //         })
          //     },
          //     class: 'test',
          //     closable: false
          //   });
          // }
        })
      }
  console.log(state.query,'state.query12431');



      const phone = getUserPhone()
      if (phone) {
        state.confirmLoading = true
        getByPhone({
          phone
        }).then(res => {
           delete res['updateTime']
           delete res['createTime']
           delete res['createBy']
           delete res['updateBy']
          state.formState = Object.assign(res)
          state.query = router.currentRoute.value.query
          //  if(res.entryType ==3){
          //    activeKey.value = 1
          //    state.formState.branchtype=''
          //    state.formState.post=''
          //    state.formState.province=''
          //    state.formState.city=''
          //    state.formState.county=''
          //    state.formState.scope=''
          //    state.formState.aaumSign=''
          //    state.formState.branchName=''
          //    state.formState.mainIndustry=''
          //   //  state.formState.id=''
          //   //  state.formState.activitiId=''
          //    state.formState.deptId=''
          //    state.formState.actStatus=null
          //    state.formState.stage=1
          // }
          if(state.query.departId){
             state.formState.entryType=1 //员工入职
          } else{
             state.formState.entryType=2 //员工和公司注册
          }



          if(state.query.deptHead){
          state.formState.deptHead=state.query.deptHead||''
          }

          if (state.formState.stage === 10) {
            /**
             * 表单stage = 10后续的操作不能改表单的stage
             * 判断是否流程进入
             **/
            state.confirmLoading = true
            if (Object.keys(state.query).includes('isActiviti')) {
              // 1.获取流程相关信息
                console.log('kkkkkkk');

              getProc(state.query.activitiId).then(res => {

                state.query.instanceId = res.instanceId
                // 2.获取待办列表
                getTaskList({
                  businessKey: res.businessKey,
                  definitionId: res.definitionId,
                  pageSize: 10,
                  pageNum: 1
                }).then(result => {
                  // 判断有没有待办
                  if (result.list.length) {
                    const obj = result.list[0]
                    state.taskId=obj.taskId
                    // 有待办，获取审批时是否可以修改表单
                    getFormKey({
                      processDefinitionId: res.definitionId,
                      taskId: obj.taskId
                    }).then(ress => {
                      if (ress.isEditable == '1') {
                        // 可以修改表单
                        activeKey.value = 1
                      } else {
                        toUrl('1')
                      }
                    }).finally(() => {
                      state.confirmLoading = false
                    })
                  } else {
                    toUrl('2')
                    state.confirmLoading = false
                  }
                }).catch(err => {
                  toUrl('2')
                  state.confirmLoading = false
                })
              }).catch((err: any) => {
                toUrl('2')
                state.confirmLoading = false
              })
            } else {
              activeKey.value = 6
            }
          } else if (state.formState.stage) {
            activeKey.value = state.formState.stage
          } else if (state.formState.stage === 0) {
            activeKey.value = 1
          }
        }).catch(err => {
           if(err.message=='签约系统默认创建账号'){
                toUrl('1')
           }else{
          message.warning('此账号不能申请！请换一个账号')
          router.go(-1)}
      //  const obj = { ...state.query }
      // router.push({
      //   path: '/hr/login',
      //   query: {
      //     ...obj
      //   }
      // })
        }).finally(() => {
          state.confirmLoading = false
        })
      } else {
        message.warning('请重新跳转连接')
      }
      console.log(state.formState,'==========123123123123');

    })
    // 获取流程数据---编辑/查看/启动
    function getProc (activitiId: string) {
      if (!activitiId) return
      return getProcessByActi({ activitiId }).then(res => {
        return res
      })
    }
    function toUrl (isActiviti?: string) {
      const obj = { ...state.query }
      if (isActiviti) {
        obj.isActiviti = isActiviti
      }
      router.push({
        path: '/hr/userExamine',
        query: {
          ...obj
        }
      })
    }
    function refresh(v){
     console.log(v,'321vvv');
        if(state.formState.activitiId){
        getProc(state.formState.activitiId).then( res => {
          if(res){
             state.instanceId=res.instanceId
          }
      })
        }

    }
    function back(){
      if(state.mode=='right'){
         state.mode='left'
      }else{
        if(activeKey.value==1 || activeKey.value==6 ){
         out()
        }else{
        activeKey.value = activeKey.value-1
        toNextTab(activeKey.value,{})
        }

      }

    }
      function out () {
      store.dispatch('Logout').then(res => {

        router.go(-1)
      }).catch((err) => {

        router.go(-1)
      })
    }
    return {
      ...toRefs(state),
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      userInfo,
      activeKey,
      toNextTab,
      changeMode,
      refresh,
      router,
      back,
      out,
      postDisabled
    }
  }
})


import { computed, defineComponent, reactive, ref, toRefs, PropType, toRef, watch, onMounted } from 'vue'
import { parseIDCard, EDIT_USER, uploadRequest,checkOnlyemail } from '@/api/hrUser'
import type { UnwrapRef } from 'vue'
import { useStore } from 'vuex'
import { FileItem, FileInfo, hrUserFormState } from '@/views/HrUser/data'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { PlusOutlined, LoadingOutlined, InboxOutlined } from '@ant-design/icons-vue'
import {  isNumber } from 'lodash'
import { getToken, getUserInfo } from '@/utils/auth'
import Autograph from '@/views/HrUser/HrUserStep/modules/Autograph.vue'
import {baseURL} from '@/config/index'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import { nextTick } from 'process'

function getBase64 (img: Blob, callback: (base64Url: string) => void) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}
export default defineComponent({
  emits: ['toNextTab'],
  props: {
    data: {
      type: Object
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    PlusOutlined, LoadingOutlined, InboxOutlined, Autograph
  },
  setup (props, context) {
    const data = toRef(props, 'data')
    const type = toRef(props, 'type')
    const disabled = computed(() => {
      if (type.value === 'detail') {
        return true
      } else {
        return false
      }
    })
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      identUpUrl: baseURL+'/sfsj-server/sys/oss/api/uploadRequest',//身份证上传地址
      url: baseURL+'/test/hr/bill/uploadPictures',
      headers: {
        'X-Access-Token': getToken()
      },
      confirmLoading: false,
      identityCardBack: false,
      identityCardFace: false,
      isShowFull: false, // 是否横屏显示
      signaturePad: null, // 存放竖屏SignaturePad对象
      signaturePadFull: null, // 存放横屏SignaturePad对象
      value: '',
      fullValue: '',
      politicalList:[
        '团员',
        '党员',
        '预备党员',
        '群众',
        '其他'
      ],
      maritalStatusList:[
        '已婚',
        '未婚',
        '离异'
      ],
      healthStatusList:[
         '健康',
        '有先天性疾病',
        '有传染性疾病',
        '有其他重大疾病',

      ]
    })
    const formState = reactive({
      identityCardBack: '',
      identityCardFace: '',
      entryApplicationId: '',
      signImage: '',
      name: null,
      sex: null,
      identityCardCode: null,
      birthday: null,
      age: null,
      political: null,
      joinPartyDate: null,
      nation: null,
      maritalStatus: null,
      nativePlace: null,
      address: null,
      emergencyContact: null,
      emergencyContactPhone: null,
      email: null,
      healthStatus: null,
      id: null,
      deptHead:null
    })
  const validateEmail = async (rule: RuleObject, value: string) => {
      if (value === '') {
        return Promise.reject('请输入邮箱号')
      } else {
        if (new RegExp(/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/).test(value)) {
          return new Promise<void>((resolve, reject) => {
            checkOnlyemail({
              email: value,
              id:formState.id
            }).then(res => {
              console.log(res,'ressssss');

              resolve()
            }).catch(err => {
              console.log(err,'errsssss');

              if (err.success) {
                resolve()
              } else {
                reject('邮箱已重复!')
              }
            })
          })
        } else {
          return Promise.reject('请输入正确格式的邮箱号!')
        }
      }
    }
    const rules = {
      identityCardFace: [{ required: true, message: '请上传身份证正面照片！' }],
      identityCardBack: [{ required: true, message: '请上传身份证反面照片！' }],
      signImage: [{ required: true, message: '请上传员工签字！' }],
         name: [
        { required: true, message: '请输入姓名', trigger: 'blur' }
      ],
      joinPartyDate: [{ required: true, message: '请选择入党日期！' }],
      email: [{ required: true, validator: validateEmail, trigger: 'change' }, { type: 'email' }],
      identityCardCode: [{ required: true, message: '请输入正确的身份证号码！',max:18,min:18}],
      sex: [{ required: true, message: '请选择性别！'}],
      political: [{ required: true, message: '请选择政治面貌！'}],
    }

    const autograph = ref(null)
    const formRef = ref()
    function openAutograph () {
      // 签名
      autograph.value.onVisible()
    }
    function toNextTab (key) {
      console.log(formRef.value.validate(), 'formRef.value')
      formRef.value.validate().then(async () => {
       context.emit('toNextTab', key, { ...formState, stage: key, id: formState.id, signImage: formState.signImage ,deptHead:router.currentRoute.value.query.deptHead?router.currentRoute.value.query.deptHead:formState.deptHead})
      }).catch((error) => {
        console.log('error', error)
      })
    }
    function out () {
      store.dispatch('Logout').then(res => {
        message.success('退出登录成功！')
        router.go(-1)
      }).catch((err) => {
        message.warning(err.message)
        router.go(-1)
      })
    }
    const fileList = ref([])
    const imageUrl = ref<string>('')

    const handleChange = (info: FileInfo, key: string) => {
      if (info.file.status === 'uploading') {
        state[key] = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        formState[key] = info?.file?.response?.result?.url
        state[key] = false
        if(formState.identityCardFace && formState.identityCardBack){
           state.confirmLoading = true
              parseIDCard(formState).then(res => {
            Object.keys(res).forEach(item => {
          formState[item] = (res)[item]

        })


        }).catch((err) => {
          message.warning(err.message)
        }).finally(() => {
          nextTick(()=>{
          state.confirmLoading = false
          })
        })
        }
        // getBase64(info.file.originFileObj, (base64Url: string) => {
        //   imageUrl.value = base64Url;
        //   state[key] = false;
        // });
      }
      if (info.file.status === 'error') {
        state[key] = false
        message.error('上传失败')
      }
    }

    const beforeUpload = (file: FileItem) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('请上传图片!')
      }
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        message.error('超出 10MB!')
      }
      return isJpgOrPng && isLt10M
    }

    onMounted(() => {
    })
    // 将base64转换为file
    const dataURLtoFile = function (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }
    function receiveQmValue (base64Codes) {
      var formData = new FormData() // 这里连带form里的其他参数也一起提交了,如果不需要提交其他参数可以直接FormData无参数的构造函数
      var file = dataURLtoFile(base64Codes, 'docpic.png')
      // convertBase64UrlToBlob函数是将base64编码转换为Blob
      formData.append('file', file)
      console.log(formData)

      uploadRequest(formData).then(res => {
        console.log('uploadRequest:', res)
        formState.signImage = res.url
      })
    }
    function selectBut(e,str){
      if( formState[str]==e){
        formState[str]=''
      }else{
        formState[str]=e
      }

    }
    watch(
      () => data.value,
      () => {
        // const { identityCardBack, identityCardFace, id, signImage } = data.value
        formState.entryApplicationId = data.value.id
        // formState.id = id
        // formState.identityCardBack = identityCardBack
        // formState.identityCardFace = identityCardFace
        // formState.signImage = signImage

            Object.keys(formState).forEach(item => {
        
            if ((data.value as any)[item] || isNumber((data.value as any)[item])) {
                    console.log('key1');

            formState[item] = (data.value as any)[item]
          }

        })
          formState.entryApplicationId = data.value.id

      }
    )
    return {
      ...toRefs(state),
      formState,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules,
      toNextTab,
      out,
      fileList,
      imageUrl,
      handleChange,
      beforeUpload,
      receiveQmValue,
      autograph,
      openAutograph,
      disabled,
      selectBut
    }
  }
})
